import React from "react";

export default function AboutUs({
  translations,
}: {
  translations: Record<string, string>;
}): React.ReactElement {
  // const translations = {
  //   landingPage: {
  //     aboutUs: {
  //       title: "Test",
  //     },
  //   },
  // };
  return (
    <section className="section" id="aboutus">
      <div className="container animateFadeIn hidden">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h1 className="title is-2  has-text-centered">
              {translations.title}
            </h1>
            <div
              className="content has-text-left"
              dangerouslySetInnerHTML={{ __html: translations.content }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
}

// <script lang="ts">
// import { Component, Prop, Vue } from 'vue-property-decorator';

// @Component({})
// export default class LandingPageAboutUs extends Vue {
//   @Prop() private translations!: any;
// }
// </script>

// <style scoped>

// </style>
