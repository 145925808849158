import Menu from "../components/Menu";
import Banner from "../components/Banner";
import AboutUs from "../components/AboutUs";
import Products from "../components/Products";
import Values from "../components/Values";
import Technologies from "../components/Technologies";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

// import jQuery from "jquery";
// import initAnimate from "../helpers/animateFadeIn";
// import initScroll from "../helpers/smoothScroll";
import React, { useState } from "react";
import getTranslations from "../helpers/translations";

export default function LandingPage(): React.ReactElement {
  const [langage, setLanguage] = useState(navigator.language);
  const translations = getTranslations(langage);

  // initAnimate(jQuery);
  // initScroll(jQuery);

  function onChangeLanguage(lang: string) {
    setLanguage(lang);
  }

  return (
    <div>
      <Menu translations={translations["landingPage"]["menu"]}></Menu>
      <Banner translations={translations["landingPage"]["banner"]}></Banner>
      <AboutUs translations={translations["landingPage"]["aboutUs"]}></AboutUs>
      <Products
        translations={translations["landingPage"]["products"]}
      ></Products>
      <Values translations={translations["landingPage"]["values"]}></Values>
      <Technologies
        translations={translations["landingPage"]["technologies"]}
      ></Technologies>
      <Contact translations={translations["landingPage"]["contact"]}></Contact>
      <Footer
        translations={translations["landingPage"]["footer"]}
        onChangeLanguage={onChangeLanguage}
      ></Footer>
    </div>
  );
}

// <template>

// </template>

// <script lang="ts">

// @Component({
//   components: {
//     'menu-landing': Menu,
//     'banner': Banner,
//     'about-us': AboutUs,
//     'products': Products,
//     'values': Values,
//     'technologies': Technologies,
//     'contact': Contact,
//     'footer-landing': Footer,
//   },
// })
// export default class LandingPage extends Vue {
//   @Prop() private translations!: any;
//   @Prop() private language!: string;

//   public mounted() {
//     initAnimate(jQuery);
//     initScroll(jQuery);
//   }

//   public changeLanguage(newLanguage: string) {
//     this.$emit('changeLanguage', newLanguage);
//     /* this.language = newLanguage;
//     this.translations = getTranslations(newLanguage); */
//   }
// }
// </script>

// <!-- Add "scoped" attribute to limit CSS to this component only -->
// <style>

// </style>
