import React from "react";
import "./Values.css";

import Image from "../assets/img/image8.png";

export default function Values({
  translations,
}: {
  translations: Record<string, string>;
}): React.ReactElement {
  return (
    <section className="section" id="values">
      <div className="container is-desktop animateFadeIn hidden">
        <h1 className="title has-text-centered is-3">{translations.title}</h1>
        <div className="columns is-multiline">
          <div className="column is-6">
            <div className="box no-shadow">
              <article className="media">
                <div className="media-content">
                  <div className="content">
                    <img src={Image} alt="toto" />
                  </div>
                </div>
              </article>
            </div>
          </div>
          <div className="column is-6">
            <div className="box no-shadow">
              <article className="media">
                <div className="media-content">
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: translations.content }}
                  ></div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
