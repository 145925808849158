import React from "react";
import { Link } from "react-scroll";

import "./Menu.css";

import Logo from "../assets/img/logo_trans2.png";

export default function MenuLanding({
  translations,
}: {
  translations: Record<string, string>;
}): React.ReactElement {
  return (
    <nav className="navbar is-light is-fixed-top">
      <div className="container is-widescreen">
        <div className="navbar-brand">
          <Link
            className="navbar-item no-padding"
            to="banner"
            spy={true}
            smooth={true}
          >
            <img
              className="no-max-height"
              src={Logo}
              width="142"
              height="128"
              alt="Toto"
            />
          </Link>
        </div>
        <div className="navbar-end">
          <Link className="navbar-item" to="aboutus" spy={true} smooth={true}>
            {translations.aboutUs}
          </Link>
          <Link className="navbar-item" to="products" spy={true} smooth={true}>
            {translations.products}
          </Link>
          <Link className="navbar-item" to="values" spy={true} smooth={true}>
            {translations.values}
          </Link>
          <Link
            className="navbar-item"
            to="technologies"
            spy={true}
            smooth={true}
          >
            {translations.technologies}
          </Link>
          <Link className="navbar-item" to="contact" spy={true} smooth={true}>
            {translations.contact}
          </Link>
        </div>
      </div>
    </nav>
  );
}
