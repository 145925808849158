import React from "react";

import "./Products.css";

import Image1 from "../assets/img/products/ladies_men/image4.jpeg";
import Image2 from "../assets/img/products/baby/unnamed.png";
import Image3 from "../assets/img/products/home/image20.png";
import Image4 from "../assets/img/products/dogs/image7.png";

export default function Products({
  translations,
}: {
  translations: Record<string, string>;
}): React.ReactElement {
  return (
    <section className="section" id="products">
      <div className="container animateFadeIn hidden">
        <h1 className="title has-text-centered is-2">{translations.title}</h1>
        <div className="columns is-multiline">
          <div className="column is-4 is-offset-2">
            <article className="has-text-centered">
              <figure>
                <img
                  src={Image1}
                  className="have-to-fit"
                  width="250"
                  height="250"
                  alt="Toto"
                />
              </figure>
              <p className="title is-5">{translations.menAndWomen}</p>
            </article>
          </div>
          <div className="column is-4">
            <article className="has-text-centered">
              <figure>
                <img
                  src={Image2}
                  className="have-to-fit"
                  width="250"
                  height="250"
                  alt="Toto"
                />
              </figure>
              <p className="title is-5">{translations.accessories}</p>
            </article>
          </div>
          <div className="column is-4 is-offset-2">
            <article className="has-text-centered">
              <figure>
                <img
                  src={Image3}
                  className="have-to-fit"
                  width="250"
                  height="250"
                  alt="Toto"
                />
              </figure>
              <p className="title is-5">{translations.home}</p>
            </article>
          </div>
          <div className="column is-4">
            <article className="has-text-centered">
              <figure>
                <img
                  src={Image4}
                  className="have-to-fit"
                  width="250"
                  height="250"
                  alt="Toto"
                />
              </figure>
              <p className="title is-5">{translations.pet}</p>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
