import React from "react";
import "./Banner.css";

export default function Banner({
  translations,
}: {
  translations: Record<string, string>;
}): React.ReactElement {
  return (
    <section
      className="hero is-dark is-fullheight banner-background"
      id="banner"
    >
      <div className="hero-head"></div>
      <div className="hero-body banner-container">
        <div className="container has-text-centered">
          <p className="title banner-title">{translations.title}</p>
          <p className="subtitle banner-subtitle">{translations.subtitle}</p>
        </div>
      </div>
      {/* <div className="hero-foot">
      <nav className="tabs is-fullwidth">
        <div className="container">
        </div>
      </nav>
</div> */}
    </section>
  );
}
