import React from "react";
import "./Technologies.css";

import Image from "../assets/img/image9.png";

export default function Technologies({
  translations,
}: {
  translations: Record<string, string>;
}): React.ReactElement {
  return (
    <section className="section" id="technologies">
      <div className="container is-desktop animateFadeIn hidden">
        <h1 className="title has-text-centered is-3">{translations.title}</h1>
        <div className="columns is-multiline">
          <div className="column is-8">
            <div className="box no-shadow">
              <article className="media">
                <div className="media-content">
                  <div className="content">
                    <img src={Image} alt="Toto" />
                  </div>
                </div>
              </article>
            </div>
          </div>
          <div className="column is-4">
            <div className="box no-shadow">
              <article className="media">
                <div className="media-content">
                  <div className="content">
                    <div className="box box-less-padding">
                      <article className="">
                        <div className="media-content">
                          <div className="content has-text-centered">
                            {translations.digital}
                          </div>
                        </div>
                      </article>
                    </div>
                    <div className="box box-less-padding">
                      <article className="">
                        <div className="media-content">
                          <div className="content has-text-centered">
                            {translations.boiledWool}
                          </div>
                        </div>
                      </article>
                    </div>
                    {translations.linking && (
                      <div className="box box-less-padding">
                        <article className="">
                          <div className="media-content">
                            <div className="content has-text-centered">
                              {translations.linking}
                            </div>
                          </div>
                        </article>
                      </div>
                    )}
                    <div className="box box-less-padding">
                      <article className="">
                        <div className="media-content">
                          <div className="content has-text-centered">
                            {translations.circularKnit}
                          </div>
                        </div>
                      </article>
                    </div>
                    <div className="box box-less-padding">
                      <article className="">
                        <div className="media-content">
                          <div className="content has-text-centered">
                            {translations.flatKnit}
                          </div>
                        </div>
                      </article>
                    </div>
                    <div className="box box-less-padding">
                      <article className="">
                        <div className="media-content">
                          <div className="content has-text-centered">
                            {translations.handKnit}
                          </div>
                        </div>
                      </article>
                    </div>
                    <div className="box box-less-padding">
                      <article className="">
                        <div className="media-content">
                          <div className="content has-text-centered">
                            {translations.crochet}
                          </div>
                        </div>
                      </article>
                    </div>
                    {/* <div className="box box-less-padding">
                    <article className="">
                      <div className="media-content">
                        <div className="content has-text-centered">
                          {translations.jersay}
                        </div>
                      </div>
                    </article>
                  </div> */}
                    <div className="box box-less-padding">
                      <article className="">
                        <div className="media-content">
                          <div className="content has-text-centered">
                            {translations.fashionKnit}
                          </div>
                        </div>
                      </article>
                    </div>
                    {translations.remaillage && (
                      <div className="box box-less-padding">
                        <article className="">
                          <div className="media-content">
                            <div className="content has-text-centered">
                              {translations.remaillage}
                            </div>
                          </div>
                        </article>
                      </div>
                    )}
                    {translations.coupeCousu && (
                      <div className="box box-less-padding">
                        <article className="">
                          <div className="media-content">
                            <div className="content has-text-centered">
                              {translations.coupeCousu}
                            </div>
                          </div>
                        </article>
                      </div>
                    )}

                    <div className="box box-less-padding">
                      <article className="">
                        <div className="media-content">
                          <div className="content has-text-centered">
                            {translations.embroideries}
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
