import translationsFR from "../i18n/fr-FR.json";
import translationsEN from "../i18n/en-GB.json";
import translationsNL from "../i18n/nl-NL.json";

const translations: {
  [key: string]: any;
} = {
  "fr-FR": translationsFR,
  "en-GB": translationsEN,
  "nl-NL": translationsNL,
};

export default function getTranslations(language: string) {
  return translations[language]
    ? translations[language]
    : translations["en-GB"];
}
