import React from "react";
import { Link } from "react-scroll";

import "./Footer.css";

export default function Footer({
  translations,
  onChangeLanguage,
}: {
  translations: Record<string, string>;
  onChangeLanguage: (lang: string) => void;
}): React.ReactElement {
  const mappingLanguages: Record<string, string> = {
    "fr-FR": "Français",
    "nl-NL": "Nederlands",
    "en-GB": "English",
  };

  const languagesDom = Object.keys(mappingLanguages).map((l) => {
    return (
      <li key={l}>
        <Link
          to="banner"
          onClick={() => onChangeLanguage(l)}
          smooth={true}
          spy={true}
        >
          {mappingLanguages[l]}
        </Link>
      </li>
    );
  });
  return (
    <section className="hero is-dark">
      <div className="hero-body">
        <div className="container">
          <div className="tabs">
            <ul>{languagesDom}</ul>
          </div>
        </div>
      </div>
    </section>
  );
}

// <script lang="ts">
// import { Component, Prop, Vue } from 'vue-property-decorator';

// @Component({})
// export default class LandingPageBanner extends Vue {

//   public mappingLanguages = {
//     'fr-FR': 'Français',
//     'nl-NL': 'Nederlands',
//     'en-GB': 'English',
//   };

//   @Prop() public language!: string;

//   public isActiveLanguage(checkLanguage: string) {
//     return this.language === checkLanguage;
//   }
// }
// </script>

// <!-- Add "scoped" attribute to limit CSS to this component only -->
// <style scoped>
// .no-max-height {
//   max-height: 3em !important;
// }

// .no-padding {
//   padding: 0 !important;
// }
// </style>
