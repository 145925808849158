import React from "react";

import "./Contact.css";

export default function Contact({
  translations,
}: {
  translations: Record<string, string>;
}): React.ReactElement {
  const content = (
    <>
      <strong>MCI Knitting Solutions SPRL</strong>
      <br />
      Queneau 50
      <br />
      7880 Flobecq, Belgium
      <br />
      <br />
      {translations.phone}:
      <ul>
        <li>+32 (0) 497 64 83 33</li>
        <li>+32 (0) 475 84 16 37</li>
      </ul>
      Email:
      <ul>
        <li>ignace.deveyt@mciknittingsolutions.be</li>
      </ul>
    </>
  );

  return (
    <section className="section" id="contact">
      <div className="container is-desktop">
        <h1 className="title has-text-centered is-3">{translations.title}</h1>
        <div className="columns is-multiline">
          <div className="column is-6">
            <div className="box no-shadow">
              <article className="media">
                <div className="media-content">
                  <div className="content">{content}</div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

/*
<div className="column is-6">
            <form method="POST" v-on:submit="sendEmail">
              <div className="field has-text-left">
                <label className="label">{{translations.landingPage.contact.form_name}}</label>
                <div className="control has-icons-left">
                  <input className="input" type="text" placeholder="" name="contactName" v-model="name">
                  <span className="icon is-small is-left">
                    <i className="fa fa-user"></i>
                  </span>
                  <span className="error"></span>
                </div>
              </div>
              <div className="field has-text-left">
                <label className="label">{{translations.landingPage.contact.form_email}}</label>
                <div className="control has-icons-left">
                  <input className="input" type="email" placeholder="" name="email" v-model="email">
                  <span className="icon is-small is-left">
                    <i className="fa fa-envelope"></i>
                  </span>
                </div>
              </div>
              <div className="field">
                <label className="label">{{translations.landingPage.contact.form_message}}</label>
                <div className="control">
                  <textarea className="textarea" name="message" v-model="message"></textarea>
                </div>
              </div>
              <div className="field">
                <div className="control has-text-centered">
                  <button type="submit" className="button"
                    v-bind:className="{
                      'is-info':  sending,
                      'is-danger':  error !== null,
                      'is-success': messageSent,
                      'is-link': !sending && error === null && ! messageSent,
                    }">
                    <span v-if="sending">
                      {{translations.landingPage.contact.sending}}
                    </span>
                    <span v-else-if="messageSent">
                      {{translations.landingPage.contact.messageSent}}
                    </span>
                    <span v-else-if="error !== null">
                      {{translations.landingPage.contact.messageError}}
                    </span>
                    <span v-else>
                      {{translations.landingPage.contact.form_send_button}}
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
*/

/* 
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import sendEmail from '../../services/api';

@Component({})
export default className LandingPageContact extends Vue {
  public name = '';
  public email = '';
  public message = '';
  public sending = false;
  public messageSent = false;
  public error = null;

  @Prop() private translations!: any;

  public async sendEmail(e: any) {
    e.preventDefault();
    if (this.sending || this.messageSent) {
      return;
    }
    this.sending = true;
    const payload = {
      email: this.email,
      name: this.name,
      message: this.message,
    };
    await sendEmail(payload);
    this.sending = false;
    this.messageSent = true;
    this.email = '';
    this.name = '';
    this.message = '';
    setTimeout(() => {
      this.messageSent = false;
    }, 2000);
  }
}
</script>

<style scoped>
.no-shadow {
  box-shadow: 0 0px 0px !important;
}
</style>
*/
